/* eslint no-param-reassign: ["error", { "props": false }] */
// @ts-check

/**
 * @typedef {{ locale: string; currency: string; site_id: number; }} Config
 * @typedef {{ type: string, message: object }} LiveGameUpdate
 */

const LOG_DANGER = '#c82333';
const LOG_INFO = '#0a95ff';

// default service config
const DEFAULT_LOCALE = "en";
const DEFAULT_CURRENCY = "USD"
const DEFAULT_SITE_ID = 1;

/**
 * @type {Config}
 */
export const defaultConfig = {
    locale: DEFAULT_LOCALE,
    currency: DEFAULT_CURRENCY,
    site_id: DEFAULT_SITE_ID
}

export class LiveCasinoWorker {
    /** @type {string[]} */ subscribedGameIds = []
    /** @type {string[]} */ subscribedJackpotRefs = []
    /** @type {string} */ baseUrl="/fragment/gamefilter";
    /** @type {string} */ locale;
    /** @type {string} */ currency;
    /** @type {number} */ site_id;
    
    /** @param {Config} config */
    constructor(config) {
        this.locale = config.locale ?? DEFAULT_LOCALE;
        this.currency = config.currency ?? DEFAULT_CURRENCY;
        this.site_id = config.site_id ?? DEFAULT_SITE_ID;
    }

    /**
     * register a gameId with the polling service
     * @param {String} gameId
     */
    subscribe(gameId) {
        if (!gameId || typeof gameId !== 'string') {
            log(`gameId must be of type string. ${typeof gameId} given`, LOG_DANGER);
            return;
        }

        if (this.subscribedGameIds.indexOf(gameId) !== -1) {
            return;
        }
        console.log("Subscribing game " + gameId);
        this.subscribedGameIds.push(gameId);
    };
    
    /**
     * @param {string} subscribedJackpotRefs
     * @param {string} locale
     */
    subscribeJackpotRef(subscribedJackpotRefs, locale) {
        this.locale = locale;

        if (this.subscribedGameIds.indexOf(subscribedJackpotRefs) !== -1) {
            return;
        }
        this.subscribedJackpotRefs.push(subscribedJackpotRefs);
    };

    async start() {
        await this.poll();
        setInterval(async () => this.poll(), 10 * 1000);
    };

    async poll() {
        if (this.subscribedGameIds.length === 0) {
            return;
        }
        await this.postback(this.subscribedGameIds)
            .then(result => broadcast(result))
            .catch(error => log(error, LOG_DANGER));
    };

    /**
     * @returns {Promise<LiveGameUpdate|void>}
     * @param {string[]} subscribedGameIds
     */
    async postback(subscribedGameIds) {
        try {
            let url = `${this.baseUrl}/ajax/${this.locale}/live-data`;
            let response = await fetch(url, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //make sure to serialize your JSON body
                body: JSON.stringify({
                    games: subscribedGameIds,
                })
            });

            if (response.status === 204) {
                return Promise.resolve();
            }
            if (response.status !== 200) {
                return Promise.reject('error retrieving live casino game data');
            }

            let result = await response.json();
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

/**
 * @param {any} result
 */
function broadcast(result) {
    if(result) {
        postMessage(result);
    }
}

/**
 * @param {string} message
 * @param {string | undefined} [color]
 */
function log(message, color) {
    console.log(`%c${message}`, `color: ${color || '#000'}`)
}

// worker binding
let instance = null;
self.onmessage = async function (event) {
    let payload = event.data;

    switch (payload.eventType) {
        case 'init':
            log('initialise live-casino worker')

            instance = new LiveCasinoWorker({
                ...defaultConfig,
                ...payload.config
            });

            if (payload.hostname === 'localhost') {
                instance.baseUrl = '';
            }

            log('starting live-casino worker')
            await instance.start();

            break;
        case 'subscribe':
            await instance.subscribe(payload.gameId);
            break;
        case 'subscribeJackpotRef':
            await instance.subscribeJackpotRef(payload.jackpotRef, payload.locale);
            break;
        default:
            break;
    }
}